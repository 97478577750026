
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IIndicatorValue } from '@/modules/common/components/ci-table';
import LosIndicator from '@/modules/common/components/los-indicator.vue';

@Component({
    components: {
        LosIndicator,
    },
})
export default class DayBetterIndicators extends Vue {
    @Prop({
        type: Object,
        default: null,
    })
    indicators!: {
        mealType: IIndicatorValue | boolean,
        numberOfGuests: IIndicatorValue | boolean,
        cancellation: IIndicatorValue | boolean,
        losRestriction: IIndicatorValue | number | boolean,
    } | null;

    get losRestriction() {
        if (!this.indicators || !this.indicators.losRestriction) {
            return '';
        }

        if (typeof this.indicators.losRestriction === 'number') {
            return String(this.indicators.losRestriction);
        }

        return (this.indicators.losRestriction as IIndicatorValue).value;
    }
}
